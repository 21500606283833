import { useState, useEffect } from 'react'
import { useApolloClient } from '@apollo/react-hooks'
import GET_LEARNING_GOALS from './getLearningGoals.graphql'
import CREATE_LEARNING_GOAL from './createLearningGoal.graphql'
import UPDATE_LEARNING_GOAL from './updateLearningGoal.graphql'
import { createContainer, useContainer } from 'unstated-next'
import { AppState } from '../state/AppState'

export const useLearningGoals = () => {
  const [learningGoals, setLearningGoals] = useState([
  ])
  const { selectedCurriculumGroup: curriculumGroup, yearGroup } = useContainer(AppState)
  const [editing, setEditing] = useState({})

  const client = useApolloClient()

  useEffect(() => {
    if (!(curriculumGroup && curriculumGroup._id)) return
    fetch()
  }, [curriculumGroup, yearGroup])

  const toggleEditing = ({ _id, title, example }) => {
    if (editing._id === _id) {
      setEditing({})
    } else {
      setEditing({ _id, title, example })
    }
  }

  const updateEditing = ({ title, example }) => {
    setEditing({ ...editing, title, example })
  }

  const submitEdit = async () => {
    const { data } = await client.mutate({
      mutation: UPDATE_LEARNING_GOAL,
      variables: {
        learningGoalId: editing._id,
        input: {
          title: editing.title,
          example: editing.example
        }
      }
    })
    const newGoal = data.updateLearningGoal
    setLearningGoals(learningGoals.map(goal =>
      goal._id === newGoal._id ? { ...goal, ...editing } : { ...goal }
    ))
    setEditing({})
  }

  const fetch = async () => {
    const { data } = await client.query({
      query: GET_LEARNING_GOALS,
      fetchPolicy: 'network-only',
      variables: { curriculumGroupId: curriculumGroup._id, yearGroup }
    })
    setLearningGoals(data && data.curriculumGroup.learningGoals)
  }

  const add = async ({ title, categoryId, example }) => {
    const { data } = await client.mutate({
      mutation: CREATE_LEARNING_GOAL,
      variables: { curriculumGroupId: curriculumGroup._id, title, categoryId, example, yearGroup }
    })
    setLearningGoals([data.createLearningGoal, ...learningGoals])
  }

  return {
    fetch,
    editing,
    toggleEditing,
    updateEditing,
    submitEdit,
    learningGoals,
    add
  }
}

export const LearningGoalsState = createContainer(useLearningGoals)
