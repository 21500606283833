import React, { useState } from 'react'
import { useContainer } from 'unstated-next'
import { LearningGoalsState } from '../useLearningGoals'
import LearningGoal from './LearningGoal'
import { LearningCategoriesState } from '../useLearningCategories'
import { TextInput, Button, Select } from 'grommet'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 30px;
    display: grid;
    grid-template-columns: 1fr;
    margin: 0 auto;
    grid-gap: 5px;
    > * {
      display: flex;
      height: 40px;
      align-items: center;
    }
`

const NewLearningGoal = styled.div`
  display: flex;
  margin-bottom: 5px;
  > :first-child {
  }
  > * {
    height: 40px;
    margin-right: 10px;
    height: 100%;
  }
  > :last-child {
    margin-right: 0px;
  }
  input { height: 100%; }
`


export default (props) => {
  const { learningGoals, add } = useContainer(LearningGoalsState)
  const [newLearningGoal, setNewLearningGoal] = useState({ title: '', category: {}, example: '' })
  const { learningCategories } = LearningCategoriesState.useContainer()

  const onAdd = () => {
    // @ts-ignore
    add({ title: newLearningGoal.title, categoryId: newLearningGoal.category._id, example: newLearningGoal.example })
    setNewLearningGoal({ title: '', category: {}, example: '' })
  }


  return <Wrapper>
    <NewLearningGoal>
      <Select
        options={learningCategories}
        value={newLearningGoal.category}
        labelKey='title'
        valueKey='_id'
        onChange={({ value }) => setNewLearningGoal({ ...newLearningGoal, category: value })}
      />
      <TextInput placeholder='Title' value={newLearningGoal.title} onChange={e => setNewLearningGoal({ ...newLearningGoal, title: e.target.value })} />
      <TextInput placeholder='Example' value={newLearningGoal.example} onChange={e => setNewLearningGoal({ ...newLearningGoal, example: e.target.value })} />
      <Button primary onClick={onAdd}>Add</Button>
    </NewLearningGoal>
    { learningGoals.map(goal =>
      <LearningGoal key={goal._id} goal={goal} />
    )
    }
  </Wrapper>
}
