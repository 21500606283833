import { useState, useEffect } from 'react'
import { useApolloClient } from '@apollo/react-hooks'
import GET_LEARNING_CATEGORIES from './getLearningCategories.graphql'
import CREATE_LEARNING_CATEGORY from './createLearningCategory.graphql'
import { createContainer } from 'unstated-next'

export const useLearningCategories = () => {
  const [learningCategories, setLearningCategories] = useState([])

  const client = useApolloClient()

  useEffect(() => {
    fetch()
  }, [])

  const fetch = async () => {
    const { data } = await client.query({
      query: GET_LEARNING_CATEGORIES,
      fetchPolicy: 'network-only'
    })
    setLearningCategories(data && data.curriculumLearningCategories)
  }

  const add = async ({ title }) => {
    const { data } = await client.mutate({
      mutation: CREATE_LEARNING_CATEGORY,
      variables: { title }
    })
    setLearningCategories([data.createLearningCategory, ...learningCategories])
  }

  return {
    fetch,
    learningCategories,
    add
  }
}

export const LearningCategoriesState = createContainer(useLearningCategories)
