import React from 'react'
import styled from 'styled-components'

interface IProps {
  category: {
    _id: string,
    title: string
  }
}

const Wrapper = styled.div`
  width: 100%;
  background-color: #E3E8EE;
  padding: 6px 12px;
`

export default (props: IProps) => {
  return <Wrapper>
    { props.category.title }
  </Wrapper>
}