import { TextInput } from 'grommet'
import React, { useState } from 'react'
import styled from 'styled-components'
import { LearningCategoriesState } from '../useLearningCategories'
import LearningCategory from './LearningCategory'
import { Button } from 'nzk-react-components'
import { colors } from '../../../theme'

interface IProps {
  categories: {
    _id: string
    title: string
  }[]
}

const NewLearningCategory = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 13px;
  input {
    border-color: ${colors.indigo};
    margin-right: 10px;
    height: 40px;
    border-radius: 6px;
    box-shadow: 0 2px 3px rgba(0,0,0,0.1);
    color: ${colors.indigo};
    max-width: 200px;
  }
  > * {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    > * { margin-right: 8px; }
    > :last-child { margin-right: 0; }
  }
`

const Wrapper = styled.div`
  > * {
    margin-bottom: 6px;
  }
`

const Title = styled.h2`
  margin-bottom: 12px;
`

export default (props: IProps) => {
  const { learningCategories, add: addCategory } = LearningCategoriesState.useContainer()
  const [newLearningCategory, setNewLearningCategory] = useState({ title: '' })

  const onAddCategory = () => {
    if (newLearningCategory.title.length <= 3) return
    addCategory(newLearningCategory)
    setNewLearningCategory({ title: '' })
  }

  return <Wrapper>
    <Title>Learning categories</Title>
    <NewLearningCategory>
      <div>Add a new category</div>
      <div>
        <TextInput
          value={newLearningCategory.title}
          onChange={e => setNewLearningCategory({ title: e.target.value })}
          onKeyPress={e => e.which === 13 && onAddCategory()}
        />
        <Button theme='primary' size='x-small' onClick={onAddCategory}>Add</Button>
      </div>
        
      </NewLearningCategory>
    {
      learningCategories.map(c => <LearningCategory key={c._id} category={c} />)
    }
  </Wrapper>
}