import React from 'react'
import { useContainer } from 'unstated-next'
import { LearningGoalsState } from '../useLearningGoals'
import { TextInput, Button } from 'grommet'
import { Link } from 'gatsby'
import { Edit, Save, Close } from 'grommet-icons'
import styled from 'styled-components'
import { colors } from '../../../theme'


const Wrapper = styled.div`
  height: 40px;
  border-radius: 7px;
  cursor: pointer;
  width: 100%;
  > :first-child {
    color: inherit;
    text-decoration: none;
    height: 100%;
    width: 100%;
    line-height: 40px;
    display: grid;
    grid-template-columns: 100px 1fr 1fr;
    grid-gap: 5px;
    width: 100%;
    > * {
      padding: 0 10px;
      background-color: ${colors.lightGray};
      border-radius: 4px;
    }
    margin-right: 10px;
  }
`

interface IProps {
  goal: {
    _id: string
    title: string
    example: string
    category: {
      title: string
    }
  }
}

export default (props: IProps) => {
  const { editing, toggleEditing, updateEditing, submitEdit } = useContainer(LearningGoalsState)
  const { goal } = props

  return <Wrapper key={goal._id}>
      { /* @ts-ignore */ }
      { editing._id === goal._id
      ? <><div>
        <div>{goal.category && goal.category.title}</div>
        { /* @ts-ignore */ }
        <TextInput placeholder='Title' value={editing.title} onChange={e => updateEditing({ ...editing, title: e.target.value })} />
        { /* @ts-ignore */ }
        <TextInput placeholder='Example' value={editing.example} onChange={e => updateEditing({ ...editing, example: e.target.value })} />
      </div>
      <Button style={{ marginRight: '10px', display: 'flex', alignItems: 'center' }} primary color='#E3E8EE' onClick={() => toggleEditing(goal)}>
        { /* @ts-ignore */ }
        <Close />
      </Button>
      <Button style={{ display: 'flex', alignItems: 'center' }} primary onClick={submitEdit}>
        { /* @ts-ignore */ }
        <Save color='#fff' />
      </Button>
      </>
      : <>
        { /* @ts-ignore */ }
        <Link to={`/learning-goal/${goal._id}`}>
        <div>{goal.category && goal.category.title}</div>
        <div>{goal.title}</div>
        <div>{goal.example || ''}</div>
      </Link>
      <Button style={{ display: 'flex', alignItems: 'center' }} primary onClick={() => toggleEditing(goal)}>
      { /* @ts-ignore */ }
        <Edit color='#fff' />
      </Button></>
  }
  </Wrapper>
}
