import React from 'react'
import styled from 'styled-components'
import LearningCategoriesList from './components/LearningCategoriesList'
import LearningGoalsList from './components/LearningGoalsList'

const Wrapper = styled.div`

`

const LearningGoals = (props) => {
  return <Wrapper>
    <div>
      <LearningGoalsList />
    </div>
    <div>
      <LearningCategoriesList />
    </div>
  </Wrapper>
}

LearningGoals.propTypes = {

}

LearningGoals.defaultProps = {

}

export default LearningGoals
