import React from 'react'
import Layout from '../components/layout'
import LearningGoals from '../components/LearningGoals'
import { LearningGoalsState } from '../components/LearningGoals/useLearningGoals'
import { LearningCategoriesState } from '../components/LearningGoals/useLearningCategories'

const LearningGoalsPage = () => (
  <Layout>
    <LearningCategoriesState.Provider>
      <LearningGoalsState.Provider>
        <LearningGoals />
      </LearningGoalsState.Provider>
    </LearningCategoriesState.Provider>
  </Layout>
)

export default LearningGoalsPage
